const noJSON = {
  // Languages
  'Language Switcher': 'Språkbytter',
  English: 'Engelsk',
  Norwegian: 'Norsk',
  Swedish: 'Svensk',
  'Total amount (Including Tax)': 'Total sum (Inkludert Mva)',

  'Go back': 'Gå tilbake',
  'Pay with card': 'Betal med kort',
  'Choose Payment Method': 'Velg betalingsmetode',

  Start: 'Start',
  Continue: 'Fortsette',

  'Registration number': 'Registreringsnummer',
  Submit: 'Send inn',

  'Parking details': 'Parkeringsdetaljer',
  'Send recipe': 'Send oppskrift',
  Home: 'Hjem',

  'All set to go': 'Alt er klart',
  'Payment failed': 'Betaling mislyktes',

  'License plate': 'Bilskilt',

  'Total cost': 'Total cost',
  'Try again': 'Prøv igjen',

  'Parking area': 'Parkeringsplass',
  Duration: 'Varighet',
  'Invoice date': 'Faktura dato',
  Amount: 'Beløp',

  'Access terminal': 'Tilgangsterminal',

  Security: 'Sikkerhet',
  'Enter your registration number': 'Skriv inn registreringsnummeret ditt',
  Next: 'Neste',
  'There is no parking on {{RegistrationNumber}}. Are you sure you have written correctly?':
    'Det er ingen parkering på {{RegistrationNumber}}. Er du sikker på at du har skrevet riktig?',

  'Gate successfully opened': 'Porten ble åpnet',
  'Gate cannot be opened': 'Porten kan ikke åpnes',

  'Cannot open gate for the given license plate':
    'Kan ikke åpne porten for oppgitt bilskilt',
  'Parking terminal': 'Parkeringsautomat',
  'We did not find anything on this license plate number. Double-check':
    'Vi fant ingenting på dette bilskiltnummeret. Dobbeltsjekk',
  'Your parking has been validated. You can now exit the parking.':
    'Din parkering er blitt godkjent. Du kan nå forlate parkeringsplassen.',
  'We found more than one match for this license plate. Write complete license plate number or check with reception':
    'Vi fant mer enn én match for dette bilskiltnummeret. Skriv hele bilskiltnummeret eller sjekk med resepsjonen',
  'An error occurred while processing your request':
    'En feil oppsto under behandlingen av forespørselen din',
};

export type TranslatedString = keyof typeof noJSON;

export default noJSON;
