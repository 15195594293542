import { combineReducers } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import ApiEndpoints from '_api/Endpoints/ApiEndpoints';

const reducer = combineReducers({
  apiEndpoints: ApiEndpoints.reducer,
});

//any to pass build, will fix later
export const middleware = (getDefaultMiddleware: () => any) =>
  getDefaultMiddleware().concat(thunk, ApiEndpoints.middleware);

export default reducer;
